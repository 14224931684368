<template>
  <div class="content">
    <!-- 头部信息 -->
    <div class="head">
      <div class="head_box">
        <div class="head_box_padding">
          <div class="head_box_info">
            <img class="head_box_info_img" v-if="operatorLogo" :src="operatorLogo" alt="">
            <div class="head_box_info_name">
              <div class="head_box_info_name_text">
                <div v-if="Boolean(cardInfo.nickname)">
                  {{ cardInfo.nickname }}
                </div>
                <div v-else>无昵称</div>
                <img @click="toComponent('personal-cardManagement', { cantRefundBalance: cardInfo.cantRefundBalance })"
                  src="../../assets/image/yyPlatfrom/edit.png" alt="">
              </div>
              <!-- 没有充值卡号 -->
              <div class="head_box_info_name_num" v-if="cardInfo.sim">
                SIM：{{ cardInfo.sim }}
              </div>
              <!-- 有充值卡号 -->
              <!-- <div class="head_box_info_name_num" v-else>卡号:{{ cardInfo.virtualId }}</div> -->
            </div>
          </div>
          <div class="head_box_crad">
            <div class="head_box_crad_status">
              <div>
                <img v-if="cardInfo.statusCode == 3 || cardInfo.statusCode == 1"
                  src="../../assets/image/yyPlatfrom/card_blockUp.png" alt="">
                <img v-else src="../../assets/image/yyPlatfrom/activated.png" alt="">
                <span :style="{ color: cardInfo.status == '已激活' ? '#FFF6D7' : '' }">{{ cardInfo.status ? (cardInfo.status ==  '待激活' ? '可激活' : cardInfo.status)
                    :
                    "----"
                }}</span>               
              </div>
              <!-- <div v-if="platformCode != 'syy' && cardInfo.endTime">
                <span style="margin-right: 8px;font-size: 14px;">{{ cardInfo.endTime ? cardInfo.endTime.slice(0, 11) : '---'  }}到期</span>
                <span>
                    &nbsp;&nbsp;
                    <template v-if="cardInfo.endTime">
                        <span v-if="new Date().getTime() >= new Date(cardInfo.endTime).getTime()">( 已到期 )</span>
                        <span v-if="new Date().getTime() < new Date(cardInfo.endTime).getTime() && cardInfo.expirationTime == 0">(今天到期)</span>
                        <span v-if="new Date().getTime() < new Date(cardInfo.endTime).getTime() && cardInfo.expirationTime >= 1 && cardInfo.expirationTime <= 5">( {{ cardInfo.expirationTime }}天后到期 )</span>                        
                    </template>
                    <template v-else>---</template>
                </span>
              </div> -->
              <!-- <div>
                <img src="../../assets/image/yyPlatfrom/flow_error.png" alt=""><span>流量错误</span>
              </div>
              <div>
                <img src="../../assets/image/yyPlatfrom/voice_error.png" alt=""><span>语音错误</span>
              </div> -->
            </div>
            <div class="head_box_crad_iccid">
              ICCID：{{ cardInfo.iccid }}
            </div>
          </div>
          <div class="head_box_noRealName" v-if="isRealName == 0">
            <van-button class="btn" size="small" block @click="checkIsNewRealName(1)">
                去实名<van-icon name="arrow" />
            </van-button>       
          </div>
          <div class="head_box_isRealName" v-if="[1, 2, 3].includes(isRealName)">
            <img src="../../assets/image/yyPlatfrom/real_name_ok.png" alt="">
          </div>
          <img class="head_box_bj" src="../../assets/image/yyPlatfrom/head_bj.png" alt="">
        </div>
      </div>
    </div>

    <!-- teb栏 -->
    <div class="teb">
      <van-tabs v-model:active="active" title-active-color="#FE3A39" line-width="64" line-height="2"
        background="#FFF6D8" swipeable @click-tab="onChangeActive">
        <!-- 话费充值 -->
        <van-tab title="话费充值">
            <div class="banner" v-if="couponGoodsList.length>0 && !Boolean(isSimple)">
                <van-swipe class="my-swipe" loop="false" @change="onChange">
                    <van-swipe-item v-for="(item,index) in couponGoodsList" :key="index" @click="pasteHandle(item)">
                        <div class="swipe-content">
                            <div class="goodsName">{{item.goodsName}}</div>
                            <div>
                                <van-button type="primary" size="small" block
                                    @click="pasteHandle(item)"    
                                >
                                    <span class="unit">￥</span>                                        
                                    <span class="salePrice">{{item.salePrice}}</span>
                                    <span class="zero">.00</span>                
                                    <span class="buy" style="padding-left: 2px;">购</span>                                                            
                                </van-button>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="banner" v-if="bannerList.length>0 && !Boolean(isSimple)">
                <van-swipe class="my-swipe" :autoplay="bannerInfo.intervalStatus ? bannerInfo.timeInterval * 1000 : 0"  indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="openUrl(item.url,item.needParams)">
                    <img :src="item.imgUrl" />
                </van-swipe-item>
                </van-swipe>
            </div>
          <div class="teb_box">
            <div class="teb_recharge" v-if="basicDataList[0]">
              <div class="teb_recharge_box" :class="basicIndex == index ? 'teb_recharge_box_active' : ''"
                v-for="(item, index) in basicDataList" :key="index" @click="paste(item, 'setMeal', index)">
                <div class="teb_recharge_padding">
                  <div class="teb_recharge_box_title">
                    {{ item.packageName }}
                  </div>
                  <div class="teb_recharge_box_price">
                    <!-- :class="[item.couponDistributionRecordId ?'teb_recharge_box_price_original':'teb_recharge_box_price_actual']" -->
                    <div class="teb_recharge_box_price_actual">￥<span>{{ item.price }}</span></div>
                    <div v-if="showPrice(item)" class="teb_recharge_box_price_original">￥{{ item.markPrice }}</div>
                    <div v-if="item.couponDistributionRecordId" class="coupon_price">
                      券后￥<span>{{ item.postCouponPrice }}</span>
                    </div>
                  </div>
                  <div class="teb_recharge_box_select" v-if="item.recommendDesc">
                    <img src="../../assets/image/yyPlatfrom/hot.png" alt="">
                    <div>{{ item.recommendDesc }}</div>
                  </div>
                  <div v-if="item.recommend"
                    :class="item.recommendDesc ? 'recommend_active' : 'teb_recharge_box_recommend'"><img
                      src="../../assets/image/yyPlatfrom/recommend.png" alt="">
                    推荐</div>
                </div>
              </div>

              

            </div>
            <!-- 客服 -->

          </div>

        </van-tab>
        <!-- 叠加包 -->
        <template v-if="Boolean(alertMsg)">
          <van-tab title="叠加包">
            <!-- 叠加包购买次数提示 -->
            <div class="empty">{{alertMsg}}</div>
          </van-tab>   
        </template>
        <template v-else>
          <van-tab title="叠加包" v-if="speedDataList[0]">
            <div class="teb_box">
              <div class="teb_recharge">
                <div class="teb_recharge_box_package " :class="speedIndex == index ? 'teb_recharge_box_active' : ''"
                  v-for="(item, index) in speedDataList" :key="index" @click="paste(item, 'superposition', index)">
                  <div class="teb_recharge_package_padding">
                    <div class="teb_recharge_box_package_title">
                      {{ item.packageName }}
                    </div>
                    <div class="teb_recharge_box_package_price">
                      <div v-if="item.couponDistributionRecordId" class="coupon_price">券后￥{{ item.postCouponPrice }}</div>
                      <div class="teb_recharge_box_package_price_actual">￥<span>{{ item.price }}</span></div>
                    </div>
                    <div class="teb_recharge_box_package_month">当月有效</div>
                  </div>
                </div>
                <div style="width:100px;"></div>
              </div>
            </div>
          </van-tab>        
        </template>
        <!-- 服务功能 -->
        <van-tab title="服务功能">
          <div class="teb_box">
            <div class="teb_serve_padding">
              <!-- 显示剩余 -->
              <div class="teb_serve">
                <div class="teb_serve_circle">
                  <van-circle v-model:current-rate="flowPercent" :rate="flowPercent" :stroke-width="110" size="80px"
                    color="#1890FF" layer-color="rgba(24, 144, 255, 0.08)" :speed="100" />
                  <van-circle v-if="showVoice" v-model:current-rate="voicePercent" :rate="voicePercent"
                    :stroke-width="110" size="80px" color="#73D13D" layer-color="#F4FBEF" :speed="100" />
                </div>
                <div class="teb_serve_data">
                  <div @click="toComponent('usage-record')">剩余流量：{{
                      surplus >= 1024 || -1024 >= surplus ? (surplus / 1024).toFixed(2) : surplus
                  }}{{ surplus >= 1024 ||
    -1024 >= surplus ? "GB" : "MB"
}}
                    <van-icon name="arrow" />
                  </div>
                  <div v-if="showVoice" @click="toComponent('usage-record')">剩余语音：{{ voiceSurplus }}min
                    <van-icon name="arrow" />
                  </div>
                </div>
              </div>
              <!-- 列表入口 -->
              <div class="teb_list">
                <ul class="infos">
                  <!-- <li @click="toComponent('personal-authenTication')">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/autonym.png" />
                    <p>实名认证</p>
                  </li> -->
                  <li @click="checkIsNewRealName(1)">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/autonym.png" />                    
                    <p>实名认证</p>
                  </li>
                  <li @click="toComponent('yyPlatfrom-familyList')" v-if="cardInfo.whitelistStatus == 1">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/kinship.png" />
                    <p>添加亲情号</p>
                  </li>
                  <li @click="
                    toComponent('personal-setMeal', {
                      cardType: cardInfo.cardType,
                      iccid: cardInfo.iccid
                    })
                  ">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/bill.png" />
                    <p>账单管理</p>
                  </li>
                  <li @click="toComponent('message-content')">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/message.png" />
                    <p>消息通知</p>
                    <div class="tips_num" v-if="noticeNum != 0">+{{ Number(noticeNum) > 99 ? 99 : noticeNum }}</div>
                  </li>
                  <li @click="judge(cardInfo.statusCode, cardInfo.status)">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/diagnosis.png" />
                    <p>智能诊断</p>
                  </li>

                  <li
                    @click="toComponent('personal-cardManagement', { cantRefundBalance: cardInfo.cantRefundBalance })">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/card_management.png" />
                    <p>卡片管理</p>
                  </li>
                  <li @click="toComponent('personal-shortMessage')"
                    v-if="cardInfo.showSms == 1 && cardInfo.operator != 4">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/note.png" />
                    <p>短信服务</p>
                  </li>
                  <li @click="toComponent('stop-recharge', { type: 1 })"
                    v-if="cardInfo.operator != 4 && !Boolean(isSimple)">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/halt.png" />
                    <p>停机保号</p>
                  </li>
                  <li @click="toComponent('personal-changecard')" v-if="changeCardShow && !Boolean(isSimple)">
                    <img alt="logo" src="../../assets/image/yyPlatfrom/diagnosis.png" />
                    <p>{{ isReceiveCard ? '领取卡片' : '换卡管理' }}</p>
                  </li>
                  <li @click="toComponent('couponList')">
                    <img alt="logo" src="../../assets/image/coupon/yuyin-coupon-icon.png" >
                    <p>我的券</p>
                  </li>
                </ul>
              </div>

              <div class="teb_logOut" @click="exit">退出登录</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

      <block v-if="speedDataList[0]">
        <drag-drop :size="60" :isFreeMove="false" :distanceToEdge="10" @clickHandle="toComponent('fb-list')" v-if="openWorkOrder == 1 && !zhongtian && !Boolean(isSimple) && active != 2"></drag-drop>
      </block>
      <block v-else>
        <drag-drop :size="60" :isFreeMove="false" :distanceToEdge="10" @clickHandle="toComponent('fb-list')" v-if="openWorkOrder == 1 && !zhongtian && !Boolean(isSimple) && active != 1"></drag-drop>
      </block>

    </div>
    <!--确认订单组件-->
    <confirm-order v-if="isSupportRecharge" ref="confirmOrderRef" :title="title" :labels="cardInfo.labels"
      :buyMultiplePackages="buyMultiplePackages" :chargeNum="Number(chargeNum)" :setMealObject="setMealObject">
    </confirm-order>
    <!-- 未实名认证提示的弹窗  -->
    <!-- <tip-popup v-if="needRealName" title="实名提示" content="尊敬的客户，根据工信部要求使用需进行实名，未实名将会无法使用，请您尽快完成实名" @onConfirm="onConfirm"
      buttonName="去实名"></tip-popup> -->
    <van-popup
      v-model:show="needRealName"
      round
      closeable
      @click-overlay="closeRealName"
      @click-close-icon="closeRealName"
      :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)"
    >
      <div class="auth_popup">
        <div class="goauthenticationImg">
          <img
            src="../../assets/image/popup/icon_goauthentication.png"
            alt=""
          />
        </div>
        <div class="goauthenticationTitle">
          尊敬的用户，请您尽快提交实名认证，完善个人资料，否则我们将依法停止通信服务
        </div>
        <div class="tips_desc">
          <div class="tips_content">
            <!-- {{
              activeRealName
                ? `应工信部要求，卡片需要实名，未实名卡片将无法使用。`
                : `应工信部要求，卡片需要实名，未实名卡片将无法使用。 \n ${cardInfo.iccid} \n 请长按卡号进行复制，使用时粘贴即可`
            }} -->
            应工信部要求，卡片需要实名，未实名卡片将无法使用
          </div>
        </div>
        <div class="viewDetail">
          <van-button class="auth-button" type="primary" @click="checkIsNewRealName(2)"
            >去认证</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 未读公告提示的弹窗 -->
    <van-popup v-model:show="noticeShow" round closeable :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)">
      <div class="notice_popup" v-if="noticeList.length > 0">
        <div class="goauthenticationImg">
          <p class="title">最新通知</p>
          <img src="../../assets/image/popup/broadcast.png" alt="" />
        </div>
        <div class="goauthenticationTitle">{{ noticeList[0].title }}</div>
        <div class="tips_desc">
          <div class="tips_content">{{ noticeList[0].content }}</div>
        </div>
        <div class="viewDetail">
          <van-button class="detail-button" type="primary" @click="toViewDetail(noticeList)">查看详情</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 新卡需要充值 -->
    <tip-popup v-if="isRecharge" title="充值提示" :content="titleTips" @onConfirm="onTopUp" buttonName="去充值"></tip-popup>
    <!-- 生效套餐 -->
    <tip-popup v-if="isEffectPakage" :title="isReceivePackage ? '领取套餐提醒' : '套餐生效提醒'" :content="titlePakage"
      @onConfirm="effectPakage" :buttonName="isReceivePackage ? '立即领取' : '立即生效'"></tip-popup>
    <!-- 换卡提示弹窗 -->
    <ad-change-card-dialog ref="adChangeCardDialog" v-if="isTipsGetCardPopup"></ad-change-card-dialog>
    <!-- 自带套餐提示 产品说弹窗太多，这个先关闭 -->
    <!-- <tip-popup v-if="isEffectPakage" title="自带套餐提示" :cancelButton="false" :content="titlePakage" buttonName="知道了">
    </tip-popup> -->

    <!-- 优惠券弹窗 -->
    <platfrom-coupon ref="platfromCoupon"></platfrom-coupon>
    <!-- 优惠券返佣版本 -->
    <!-- 优惠券面板 -->      
    <coupon-container
    ref="couponNotice"    
    :couponObject="couponObject"    
    ></coupon-container>
    <!-- 跳转小程序弹窗 -->   
    <van-popup v-model:show="realNameFlag" round closeable>
        <div class="realNameBox">
            <div class="realNameContent">
                <slot>
                    <div class="title">前往小程序实名</div>
                </slot>
                <slot>
                <div class="choice">
                    <div class="cancel" @click="realNameFlag = false">取消</div>
                    <div class="confirm">                        
                        <launch-btn v-if="realNameFlag && cardInfo.iccid" :btnText="'去实名'" :iccid="cardInfo.iccid.substring(0,19)"></launch-btn>
                    </div>                   
                </div>
                </slot>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { toRefs, reactive, onUnmounted, onMounted, getCurrentInstance, computed, nextTick } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import {
  queryCardInfo,
  queryCardStatus,
  checkExchangeCardTime,
  diagnosis,
  GetEffectPackage,
  queryCardReal,
  realNameUrlYuYin,
  pageNoShowIccidFetchRealUrl,
  synWhiteList,
  NoticeNum,
  NoticeList,
  msgNoticeInfo,
  getUnreadTicketMessage,
  advertLog,
  zyAdBannerList,
  zyAdBannerInfo,
  queryPackages,
  getDialogCoupon,
  remindCoupon,
  getconf,
  testCard
} from "_API_/api.services";
import {
  baseUrl,
  queryPurchaseRecord,
} from "_API_/zuJiApi";
import { setStore, getStore, removeStore } from "@/utils/store";
import { isDevice, daysUntil } from "@/utils/utils";
import { Dialog } from 'vant';
import { trackSubmit, handleToLowerCase } from  '@/utils/track'
import jssdk from '@/assets/js/jssdk.js'
import model from '@/utils/mobileModal'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const state = reactive({
      basicIndex: 0,//基础套餐列表选中框
      speedIndex: 0,//叠加套餐选中框
      alertMsg: '1', // 叠加包购买次数提示
      basicDataList: [], //基础套餐列表
      speedDataList: [], //叠加套餐列表
      buyMultiplePackages: 0, // 多套餐购买 0-关闭 1-开启
      title: "", //弹窗顶部标题
      chargeNum: "", // 选中充值包的金额
      setMealObject: {}, //套餐对象
      couponObject: {}, // 优惠券对象
      isSupportRecharge: false,
      active: null,
      flowPercent: 0, //进度条 剩余流量
      voicePercent: 0,//进度条语音剩余
      isSimple: 0, // 0 常规版 、 1 简版
      platformCode: "", //平台code 码
      surplus: "", //剩余流量
      usedFlow: '', // 已用流量
      voiceSurplus: "", // 剩余语音
      cardInfo: { iccid: "", status: "", iccids: [],labels: [] }, //卡号信息
      isRealName: "", //获取实名状态  参数说明:0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
      noticeShow: false, //未读公告提示的弹窗展示
      unreadMsgNumber: 0, //未读客服回复数量
      noticeNum: 0, //未读公告数量
      noticeList: [], //未读公告数据源
      isRecharge: false, //充值展示
      isEffectPakage: false, //生效套餐展示
      titleTips: "", //充值标题展示
      titlePakage: "", //生效套餐标题展示
      isReceivePackage: false, // 领取套餐提示
      showVoice: false, //语音环形图展示
      lineColor: "#fff", //流量环形图进度条颜色
      lineColor_: "#fff", //语音环形图进度条颜色
      cardUseAble: true, //卡使用状态
      changeCardShow: false, //换卡入口展示
      isShowGetCardAd: false, //领取卡片轮播广告
      isReceiveCard: false, //领取卡片入口
      isTipsGetCardPopup: false, //提示换卡弹窗
      openWorkOrder: 2, //工单入口展示
      shopUrl: "", //商城入口展示
      needRealName: false, //是否需要实名
      needRealName_: false, //遮罩层的实名提示
      realNameUrl: "", //实名跳转链接
      activeRealName: "", //点击去实名标识
      theme: "", //主题名称
      adDialogShow: "", // 广告弹窗
      judgePackage: true, //智能诊断前置条件，检测套餐是否生效
      kefu_URL: "", //客服URL
      qiWei_kefu: false, //是否企微客服
      kefu_complaint_URL: '',
      flowStatus: 'all',   //展示流量： used : 已用 /  all ： 剩余
      zhongtian: false,
      bannerList: [], // 广告数组
      bannerInfo: {}, // 广告参数     
      showZujiOrder: window.SITE_CONFIG['showZujiOrder'] || false,
      dialogInfo: {}, // 弹窗信息
      dialogShow: false, // 弹窗是否显示
      // 临时弹窗方案
      promptInfo: {},
      promptDialogShow: false,
      activeNames: ['1'],
      couponGoodsList: [],
      realNameFlag: false
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    var u = navigator.userAgent;
    state.zhongtian = u.indexOf('zhongtian') > -1
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;
    const methods = {
        handleLaunchBtn () {
            return new Promise((resolve, reject) => {     
                console.log('model---------:',model);           
                if(model && model.indexOf('iPhone') > -1 && !getStore({ name: 'reloaded' })){         
                    setStore({
                        name: 'reloaded',
                        content: true,
                        type: 'session'
                    })
                    location.reload()
                    return
                }
                if (isDevice() == 'wx') {
                    console.log('sdk配置');
                    // 初始化jssdk的config
                    getconf({
                        url: location.href.split('?')[0],
                    }).then(({ data: res }) => {
                        if (res.code == 0 && !res.data.code) {                    
                            jssdk.config(res.data)      
                            resolve(true)                                                   
                        } else {
                            resolve(false)
                        }
                    })
                }
            })                    
        },
      onChangeActive(e) {//teb 切换下标
        state.active = e.name
        sessionStorage.setItem('ActiveIndex', e.name)
      },
      onTopUp() {
        state.active = 0
        sessionStorage.setItem('ActiveIndex', state.active)
      },
      //价格选择性展示
      showPrice(item) {
        if(item.couponDistributionRecordId){
          return false;
        }
        
        if (item.markPrice > 0 && item.markPrice > item.price) {
          return true;
        } else {
          return false;
        }
      },
      cycleCategoryEnum(val) {
        switch (val) {
          case 1:
            return "天"
          case 2:
            return "个月"
          case 3:
            return "个月"
          case 4:
            return "年(12月)"
          case 5:
            return "年(360天)"
          default:
            return "天"
        }
      },
      categoryEnum(val) {
        switch (val) {
          case 1:
            return "天"
          case 2:
            return "月"
          case 3:
            return "联通月"
          case 4:
            return "年(12月)"
          case 5:
            return "年(360天)"
          default:
            return "天"
        }
      },
      // 选中充值包的方法
      paste(item, type, index) {
				trackSubmit({
					eventCode: handleToLowerCase(state.theme)+'_select_package_click',
					packageId: item.packageId,
					packageName: item.packageName,
					packageCategory: item.category,
					packageType: item.packageType,
					packageCycle: item.cycle,
          packageCycleType:methods.categoryEnum(item.cycleCategory),
					packageAmount: item.price,
					packageSort: item.sort,
					isRecommend: item.recommend,
					recommendWords: item.recommendDesc
				})
        switch (type) {
          case "setMeal":
            state.basicIndex = index;
            state.title = "确认订单";
            state.chargeNum = Number(item.price);
            state.setMealObject = item;
            ctx.$refs.confirmOrderRef.actionType = 1;
            console.log('ctx.$refs.confirmOrderRef:', ctx.$refs.confirmOrderRef);
            nextTick(() => {
              ctx.$refs.confirmOrderRef.payInfo.money = Number(item.price) || 0;
              ctx.$refs.confirmOrderRef.isShow();
              ctx.$refs.confirmOrderRef.payInfo.orderType = "ordinaryOrder";
            });
            break;
          case "superposition":
            state.speedIndex = index;
            state.title = "确认订单";
            state.chargeNum = Number(item.price);
            state.setMealObject = item;
            ctx.$refs.confirmOrderRef.actionType = 1;
            nextTick(() => {
              if (
                (new Date().getTime() >
                  new Date(item.cardPackageEndTime).getTime() -
                  state.preRechargeDay * 24 * 60 * 60 * 1000) && item.cycleCategory > 3
              ) {
                return tool.confirm(
                  {
                    title: "提示",
                    msg:
                      "离年度结算已经很近，购买叠加包后将会随年度结算一起失效，是否购买？"
                  },
                  () => {
                    ctx.$refs.confirmOrderRef.payInfo.money =
                      Number(item.price) || 0;
                    ctx.$refs.confirmOrderRef.isShow();
                    ctx.$refs.confirmOrderRef.payInfo.orderType = "ordinaryOrder";
                  },
                  () => { }
                );
              }
              ctx.getSuperposition(item);
            });
            break;
          default:
            break;
        }
      },
      //叠加包弹窗判断
      getSuperposition(item) {
        ctx.$refs.confirmOrderRef.payInfo.money = Number(item.price) || 0;
        ctx.$refs.confirmOrderRef.isShow();
        ctx.$refs.confirmOrderRef.payInfo.orderType = "ordinaryOrder";
      },
      toUrl(url) {
        console.log(url)
        location.href = url
      },
      //去实名
      async onConfirm() {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){            
            if(state.cardInfo.newReal == 1 && state.realNameUrl && (state.cardInfo.isRealName == 0 || state.cardInfo.isRealName == 2)){
              location.href = state.realNameUrl;
              return
            }
            if(state.cardInfo.newReal == 1 && (state.cardInfo.isRealName == 0 || state.cardInfo.isRealName == 2)){
              methods.realLink()
              return
            }
            if (state.activeRealName) {
              ctx.realnameFn(state.cardInfo);
              return;
            }
            if (state.needRealName && state.needRealName_) {
              router.push({
                name: "personal-authenTication"
              });
              return;
            }
            location.href = `${state.realNameUrl}?card=${state.cardInfo.iccid}`;
        }
      },
      //去实名
      async toRealName() {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){                               
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){            
            if(state.cardInfo.newReal == 1 && (state.isRealName == 0 || state.isRealName == 2) && !state.passRealNameUrl){
              //ct-boss卡实名跳转
              methods.realLink()
              return
            }
            if (state.activeRealName) {
              ctx.realnameFn(state.cardInfo);
              return;
            }
            if (state.needRealName && state.needRealName_) {
              router.push({
                name: "personal-authenTication"
              });
              return;
            }
            // 判断isNewCTBossRealName字段 
            if(state.isNewCTBossRealName == 1){            
                return location.href = state.realNameUrl
            }
            location.href = `${state.realNameUrl}?card=${state.cardInfo.iccid}`;
        }
      },
      //点击未实名弹窗的取消图标 && 遮罩层 触发
      closeRealName() {
        state.needRealName_ = true;
      },
      //公告弹窗
      noticePopup() {
        if (state.noticeNum > 0) {
          // 如果是系统实名 且 通道填写了链接 遮罩层要显示 实名弹窗亦要弹出
          if (state.isRealName == 2 && state.realNameUrl) {
            state.needRealName = true;
            state.needRealName_ = true;
            return;
          }
          if (state.noticeList.lenghth > 0) {
            state.noticeShow = true;
          }
        }
      },
      //状态码弹窗
      warnPopup() {
        return new Promise(resolve => {
          let conf = tool.getCardStatusText(state.cardInfo.statusCode); //卡状态配置
          if (conf.router || conf.router_) {
            tool[conf.router ? "confirm" : "alert"](
              {
                title: "提示",
                msg: conf.msg,
                cancelButtonText: conf.cancelButtonText,
                confirmButtonText: conf.confirmButtonText,
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                if (
                  (conf.router === "fb-list" || conf.router_ === "fb-list") &&
                  Boolean(state.kefu_URL)
                ) {
                  return ctx.keFuHandle()
                }
                router.push({
                  name: conf.router || conf.router_,
                  query: {
                    card: state.cardInfo.iccid,
                    type: 0
                  }
                });
                resolve(false);
              },
              () => {
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
      },
      //退出
      exit() {
        store.dispatch("UserLogout");
        removeStore({ name: "tongLianInfo" });
        removeStore({ name: "isSimple", type: "session" });
        removeStore({ name: "userOpenId", type: "session" });
        removeStore({ name: "userBind", type: "session" });
        removeStore({ name: "initAd", type: "session" });
        removeStore({ name: "isShowGetCardAd", type: "session" });
        sessionStorage.removeItem('ActiveIndex')
        location.reload();
      },
      typeRealName(realName) {
        realName = Number(realName)
        switch (realName) {
          case 0:
            return '未实名'
          case 5:
            return '部分实名'
          default:
            return '已实名'
        }
      },
      // 客服链接跳转
      keFuHandle() {
        // 跳转企微客服
        state.qiWei_kefu && (location.href = state.kefu_URL);
        // 跳转智齿客服
        !state.qiWei_kefu && (location.href = `${state.kefu_URL
          }&params=${JSON.stringify({
            iccid: state.cardInfo.iccid
          })}&customer_fields=${JSON.stringify({
            customField1: state.cardInfo.iccid
          })}`);
      },
      realLink(){//ct-boss卡实名跳转
          realNameUrlYuYin({ card: state.cardInfo.iccid },true).then(({ data }) => {
            if (data.code == 0 && data.data.realNameUrl) {
              location.href = data.data.realNameUrl
            } else if (data.code != 0) {
              tool.toast({
                msg: data.msg
              });
            }
          });
      },
      // 点击实名前 调用接口判断 1. 是否满足三个通道 2. 是否有手机号（无：跳转手机绑定页面） 3. 实名链接
      async checkIsNewRealName (type=1) {        
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        console.log('state.realNameFlag:',state.realNameFlag);
        if(!state.realNameFlag){
            pageNoShowIccidFetchRealUrl({
              card: state.cardInfo.iccid,
              backUrl: window.location.origin + '/query?iccid=' + state.cardInfo.iccid
            }).then(({ data }) => {
              // 接口调用成功
              if (data.code == 0) {
                // 不满足条件走原来的逻辑
                if (!data?.data?.isNewTelecomRealName) {
                    if (type == 1) {
                        // 原页面实名
                        ctx.toComponent('personal-authenTication')
                    } else {
                        // 原弹窗实名
                        ctx.toRealName()
                    }                  
                // 没手机号去绑定
                } else if (data?.data?.isNeedMobile == 1) {
                  console.log('跳转手机号绑定页面')
                  router.push({
                    name: 'personal-noShowIccidBindMobile',
                    query: {
                      card: state.cardInfo.iccid,
                      // virtualId: state.cardInfo.virtualId,
                      // iccid: state.cardInfo.iccid
                      // realNameUrl: data.data.realNameUrl
                    }
                  });
                } else if (data.data.realNameUrl) {
                // 满足上述 直接跳转
                  location.href = data.data.realNameUrl
                } else {
                  tool.toast({
                      msg: '未配置实名链接，请联系客服！'
                  });    
                }
                return
              }
              // 错误提示
              tool.toast({
                  msg: data.msg
              });
            });
        }
      },
      //路由跳转
      toComponent(name, type) {
        if(name == 'personal-authenTication' && state.cardInfo.newReal == 1 && state.realNameUrl && (state.isRealName == 0 || state.isRealName == 2)){
          //ct-boss卡实名跳转
          location.href = state.realNameUrl;
          return
        }
        if(name == 'personal-authenTication' && state.cardInfo.newReal == 1 && (state.cardInfo.isRealName == 0 || state.cardInfo.isRealName == 2)){
          methods.realLink()
          return
        }
        if (name === "fb-list" && Boolean(state.kefu_URL)) {
          return ctx.keFuHandle()
        }
        if (name === 'personal-changecard') {
          store.dispatch("SetUserInfo", { isPackage: false })
        }
        if (type != '') {
          store.dispatch("SetUserInfo", { isPackage: true })
        }
        router.push({
          name,
          query: type
        });
      },
      //公告弹窗最新通知 =>查看详情
      toViewDetail(noticeList) {
        msgNoticeInfo({
          msgNoticeId: noticeList[0].id,
          card: state.cardInfo.iccid
        }).then(({ data: res }) => {
          router.push({
            name: "message-notice",
            query: noticeList[0]
          });
        });
      },
      //智能诊断=>检测是否有套餐
      judgeSetMeal() {
        let confUsage = tool.getCardStatusUsageText(state.cardInfo.statusCode); //卡状态配置 未实名 有套餐 用量大于等于90% 提醒文本
        let confNewCard = tool.getCardStatusNewCardText(
          state.cardInfo.statusCode
        ); //卡状态配置 未实名 无套餐 为新卡 提醒文本
        //后台配置主动实名或者不实名 则判断是否有套餐 有套餐则判断用量是否大于等于90%

        // 判断语音
        if (
          state.cardInfo.totalVoice !== null &&
          state.cardInfo.totalVoice != 0
        ) {
          var voiceUsage = state.cardInfo.usedVoice / state.cardInfo.totalVoice;
        }
        // 判断流量
        if (
          state.cardInfo.totalFlow !== null &&
          state.cardInfo.totalFlow != 0
        ) {
          var flowUsage = state.cardInfo.usedFlow / state.cardInfo.totalFlow;
        }
        var bothUsage = false;
        // 判断语音 流量 使用均大于等于90%
        if (voiceUsage >= 0.9 && flowUsage >= 0.9) {
          bothUsage = true;
        }
        if (voiceUsage >= 0.9 || bothUsage || flowUsage >= 0.9) {
          tool.confirm(
            {
              title: "提示",
              msg: voiceUsage
                ? confUsage.totalVoiceMsg
                : bothUsage
                  ? confUsage.bothMsg
                  : confUsage.totalFlowMsg,
              cancelButtonText: confUsage.cancelButtonText,
              confirmButtonText: confUsage.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confUsage.rechargeRouter,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (
          state.cardInfo.totalFlow == 0 ||
          state.cardInfo.totalFlow === null
        ) {
          //后台配置主动实名或者不实名 无套餐
          tool.confirm(
            {
              title: "提示",
              msg: confNewCard.msg_,
              cancelButtonText: confNewCard.cancelButtonText,
              confirmButtonText: confNewCard.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confNewCard.router_,
                query: {
                  type: 0
                }
              });
            }
          );
        } else {
          //常规诊断
          router.push({
            name: "intelligent-diagnosis",
            query: {
              usageCode: state.cardInfo.statusCode
            }
          });
        }
      },
      //智能诊断=>常规去诊断
      routineJudge() {
        // 已断网 且 非DCP的
        if (state.cardInfo.statusCode == 13 && state.cardInfo.isDCP == 0) {
          //常规诊断
          router.push({
            name: "intelligent-diagnosis",
            query: {
              usageCode: state.cardInfo.statusCode
            }
          });
          return;
        }
        // 未实名 且 通道设置了强制实名
        if (
          state.cardInfo.isRealName == 0 &&
          state.cardInfo.realNameType == 1
        ) {
          state.needRealName = true;
          return;
        }
        //检测是否有套餐
        ctx.judgeSetMeal();
      },
      //智能诊断=>已断网
      disconnectRealName(type) {
        let confRealName = tool.getCardStatusRealNameText(
          state.cardInfo.statusCode
        ); //卡状态配置 是DCP 且 已经实名 提醒文本
        if (state.cardInfo.statusCode == 13 && type == 0) {
          //常规诊断
          ctx.routineJudge();
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName != 0
        ) {
          tool.confirm(
            {
              title: "提示",
              msg: confRealName.msg_,
              cancelButtonText: confRealName.cancelButtonText,
              confirmButtonText: confRealName.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confRealName.router_,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName == 0
        ) {
          /**
           * @param {type} realNameType 无实名 0 强制实名 1 主动实名 2 被动实名 4
           */
          //后台配置强制实名 则弹起实名弹窗
          if (state.cardInfo.realNameType == 1) {
            state.needRealName = true;
            return;
          }
          //后台配置主动实名或者不实名  则检测是否有套餐
          ctx.judgeSetMeal();
        }
      },
      //智能诊断
      judge() {
        // 聚合卡 点击智能诊断 若有未生效套餐 则提示生效
        if (state.cardInfo.operator == 4 && state.judgePackage) {
          // 卡诊断
          return ctx.needCharge(state.cardInfo.iccid);
        }

        // 聚合卡 暂不支持智能诊断
        if (state.cardInfo.operator == 4) {
          return tool.toast(
            {
              msg: "该卡暂不支持",
              duration: 1500
            },
            () => { }
          );
        }
        let cardText = tool.getCardStatusType(state.cardInfo.statusCode); //卡状态类型
        let conf = tool.getCardStatusText(state.cardInfo.statusCode); //卡状态配置 常规提醒文本
        if (conf.toast) {
          tool[conf.toast]({
            msg: cardText + conf.msg_,
            duration: 3000
          });
        } else if (state.cardInfo.statusCode == 14) {
          state.needRealName = true;
          state.needRealName_ = true;
        } else if (
          (state.cardInfo.isDCP == 0 || state.cardInfo.isDCP == 1) &&
          state.cardInfo.statusCode == 13
        ) {
          ctx.disconnectRealName(state.cardInfo.isDCP);
        } else if (state.cardInfo.statusCode == 15) {
          tool.confirm(
            {
              title: "提示",
              msg: conf.msg,
              cancelButtonText: conf.cancelButtonText,
              confirmButtonText: conf.confirmButtonText,
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: conf.router,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (!state.isEffectPakage && state.judgePackage) {
          // 卡诊断
          ctx.needCharge(state.cardInfo.iccid);
        } else {
          //常规诊断
          ctx.routineJudge();
        }
      },


      // 检测换卡日期
      exchangeCardParams(iccid) {
        return new Promise(resolve => {
          checkExchangeCardTime(iccid).then(({ data: res }) => {
            resolve(res);
          });
        });
      },
      //检测卡=>需要重新充值
      needCharge(iccid) {
        state.changeCardShow = false;
        state.isReceivePackage = false;        
        diagnosis(iccid, isDevice()).then(async ({ data: res }) => {
          state.isSupportRecharge = true
          trackSubmit({
            eventCode: handleToLowerCase(state.theme) + '_home_page',
            cardStatus: state.cardInfo.statusCode,
            endTime: state.cardInfo.endTime,
            residualFlow: state.cardInfo.residualFlow,
            residualVoice: state.cardInfo.residualVoice,
            realNameStatus: state.cardInfo.isRealName,
            isNewCard: state.cardInfo.isNewCard ? 1 : 0,
            isIneffectivePackage:res.code == 205 ? 1 : 0,// 是否有未生效的自带套餐  1 是  0  否
          })
          // 未绑定手机号码
          if (res.code == 201) {
            tool.confirm(
              {
                title: "绑定手机号码提醒",
                msg: "您的卡片还未绑定手机号码，绑定后可以直接输入手机号码查询卡片",
                cancelButtonText: "取消",
                confirmButtonText: "去绑定",
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: "personal-bindMobile",
                  query: {
                    isExchangeCode: res.isExchangeCode || ""
                  }
                });
              }
            );
            return;
          }
          // 正常换卡
          if (res.code == 207) {
            let result = await ctx.exchangeCardParams(iccid);
            if (result.msg === "true") {
              let changeCardData = res.data;
              state.changeCardShow = true;
              if (changeCardData.status == 2) {
                state.cardUseAble = false;
                // 保存换卡数据
                setStore({
                  name: "changeCardData",
                  content: changeCardData,
                  type: "session"
                });
                return;
              }
              // status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
              let msg,
                virtualId = state.cardInfo.virtualId;
              if (changeCardData.status == 5) {
                msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                  }的换卡申请被驳回,请前往查看`;
              } else if (
                changeCardData.status == 1 ||
                changeCardData.status == 2 || changeCardData.status == 6 || changeCardData.status == 7
              ) {
                msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                  }需要更换卡片,请前往申请换卡`;
              } else if (changeCardData.status == 3) {
                msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                  }换卡申请已通过,快递${changeCardData.expressCompany +
                  changeCardData.expressNo}`;
              }
              tool.alert(
                {
                  msg,
                  title: "重要提示",
                  confirmButtonText: `立即${changeCardData.status == 3 ? '置换' : '申请'}`
                },
                () => {
                  // 保存换卡数据并前往换卡页面
                  setStore({
                    name: "changeCardData",
                    content: changeCardData,
                    type: "session"
                  });
                  router.push({
                    name: "personal-changecard"
                  });
                }
              );
              return;
            }
            state.cardUseAble = false;
            return tool.toast(
              {
                msg: "换卡时间已过期，请联系客服"
              },
              () => { }
            );
          }
          // 提示换卡
          if (res.code == 208) {
            store.dispatch("SetUserInfo", { isPackage: false })
            if (Boolean(res.appId)) {
              store.dispatch("SetUserInfo", {
                isConfigAppid: true,
                configAppid: res.appId,
              })
            }
            let changeCardData = res.data;
            state.changeCardShow = true;
            state.isShowGetCardAd = false
            state.isReceiveCard = true
            //检测生效套餐
            state.judgePackage = false;
            // 保存换卡数据并前往换卡页面
            setStore({
              name: "changeCardData",
              content: changeCardData,
              type: "session"
            });
            // 保存提示换卡标识
            setStore({
              name: "isShowGetCardAd",
              content: true,
              type: "session"
            });
            // status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
            if (changeCardData.status == 1) {
              //套餐中心显示换卡广告
              setStore({
                name: `isChangeCardInPackage`,
                content: true,
                type: "session"
              });
              state.isShowGetCardAd = true //为提示换卡 且 为待申请 则显示轮播换卡广告
              if (state.isSupportRecharge) {
                return ctx.changeCardPopup(res.data) //为提示换卡 且 为待申请 则显示领取卡片弹窗
              }
            }
            if (changeCardData.status == 4) {
              state.changeCardShow = false //为提示换卡 且 为置换成功 则不显示领取卡片入口
            }
            let msg,
              virtualId = state.cardInfo.virtualId;
            if (changeCardData.status == 2) {
              msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }已提交领卡申请,请前往查看`;
            } else if (changeCardData.status == 3) {
              msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }领卡申请已通过,快递${changeCardData.expressCompany +
                changeCardData.expressNo}`;
            } else if (changeCardData.status == 5) {
              msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请被驳回,请前往查看`;
            } else if (changeCardData.status == 6 || changeCardData.status == 8) {
              msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请有待支付费用,请前往支付`;
            } else if (changeCardData.status == 7) {
              msg = `您的卡:${!virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请运费支付中,请前往查看`;
            }
            tool.confirm(
              {
                msg,
                title: "重要提示",
                confirmButtonText: "立即前往",
                cancelButtonText: "取消",
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: "personal-changecard"
                });
              }
            );
            return;
          }
          // 换imei
          if (res.code == 301) {
            tool.confirm(
              {
                title: "提示",
                msg: res.msg,
                confirmButtonText: "去解绑",
                confirmButtonColor: tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: "personal-changeImei"
                });
              }
            );
            return;
          }
          // 去充值
          if (res.code == 203) {
            //检测生效套餐
            state.judgePackage = false;
            //停机保号状态不显示充值提示语
            if (state.cardInfo.statusCode == 17) {
              return;
            }
            state.isRecharge = true;
            state.titleTips = res.msg;
            return;
          }
          //去生效套餐
          if (res.code == 205) {
            state.isEffectPakage = true;
            state.titlePakage = res.msg;
            return;
          }
          // 去领取套餐
          if (res.code == 209) {
            state.isReceivePackage = true
            state.isEffectPakage = true;
            state.titlePakage = res.msg;
            return;
          }
          //类型为穿戴
          if (res.code == 2099 || res.code == 2100) {
            return ctx.clickChuangDai(res)
          }
          // 异常
          if (res.code == 500) {
            tool.alert({
              title: "提示",
              msg: res.msg
            });
            return;
          }
          if (res.code == 0) {
            //检测生效套餐
            state.judgePackage = false;
            // 查询实名认证跳转
            await methods.queryCardRealFn();
            if (state.needRealName) return;
          }
          let flag = methods.judgeIsWhitelist();
          //判断是否有状态弹窗要展示
          if (flag) {
            await ctx.warnPopup();
            //判断是否要展示公告弹窗
            ctx.noticePopup();
            return;
          }
          return;
        });
      },
      //套餐生效
      packageTakeEffect() {
        GetEffectPackage(state.cardInfo.iccid).then(({ data: res }) => {
          if (res.code == 0) {
            if (state.isReceivePackage) {
              tool.toast(
                {
                  msg: "领取套餐成功",
                  duration: 1000,
                },
                () => {
                  state.active = 0
                  sessionStorage.setItem('ActiveIndex', state.active)
                }
              );
              return
            }
            tool.toast({
              msg: "生效套餐成功",
              duration: 3000
            });
            ctx.getCardInfo(state.cardInfo);
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //关闭充值弹窗、
      closeHandle(msg) {
        state.isRecharge = msg;
      },
      //关闭生效套餐弹框
      closeHandle_(msg) {
        state.isEffectPakage = msg;
      },
      //立即生效套餐
      effectPakage() {
        state.isEffectPakage = false;
        // 立即生效套餐
        ctx.packageTakeEffect();
      },
      // 实名检测
      queryCardRealFn() {
        /**
         * @param {type} isRealName 0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
         */
        return queryCardReal(state.cardInfo.iccid).then(({ data }) => {
          let res = data.hasOwnProperty("data") ? data.data : "";
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.realNameUrl &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true;
            state.realNameUrl = data.data.realNameUrl; //保存实名链接

            return;
          }
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.hasOwnProperty("activeRealName") &&
            data.data.activeRealName == 1 &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true;
            state.activeRealName = data.data.activeRealName; //保存点击实名标识
            return;
          }
          if (data.code !== 0) {
            return tool.toast({
              msg: data.msg
            });
          }
        });
      },
      // 获取实名链接
      realnameFn({ isRealName }) {
        if (Boolean(state.cardInfo.virtualId) && !isRealName) {
          // 跳转到实名中转页
          return router.push({
            name: "personal-authenTication",
            query: {
              card: state.cardInfo.virtualId
            }
          });
        }
        realNameUrlYuYin({ card: state.cardInfo.iccid },true).then(({ data }) => {
          if (data.code == 0 && data.data.realNameUrl) {            
            tool.alert(
              {
                title: "提示",
                msg: `请记住以下实名卡号 \n ${state.cardInfo.iccid} \n 请长按卡号进行复制，使用时粘贴即可`
              },
              () => {
                location.href = `${data.data.realNameUrl}?card=${state.cardInfo.iccid}`;
              }
            );
            return;
          } else if (data.code != 0) {
            tool.toast({
              msg: data.msg
            });
            return;
          }
        });
      },
      // 亲情号码检测
      judgeIsWhitelist(type) {
        let timeId = null;
        if (state.cardInfo.isWhitelist == 0 || type == 2100) {
          tool.confirm(
            {
              title: "提示",
              msg: "温馨提示：只有加入亲情号码才可正常通话使用",
              cancelButtonText: "我知道了",
              confirmButtonText: "立即添加",
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: "yyPlatfrom-familyList",
              });
            },
            () => {
              clearInterval(timeId);
            }
          );
          timeId = setTimeout(() => {
            router.push({
              name: "yyPlatfrom-familyList",
            });
          }, 3000);
          return false;
        }
        return true;
      },
      // 同步亲情号码
      sycWhiteIpFn() {
        tool.confirm(
          {
            msg: "请确定是否同步亲情号码？",
            confirmButtonText: "立即同步",
            confirmButtonColor: tool.getThemeList(state.theme)
          },
          () => {
            synWhiteList({
              cards: state.cardInfo.iccid
            }).then(({ data }) => {
              if (data.code != 0) {
                let msg = data.msg.replace('白名单','亲情号码')
                return tool.alert({
                  title: "提示",
                  msg
                });
              }
              return tool.alert(
                {
                  title: "提示",
                  msg: "亲情号码同步成功"
                },
                () => {
                  methods.getCardInfo(store.state.user.userInfo);
                }
              );
            });
          }
        );
      },
      //获取查询的卡号数据
      getCardInfo(info) {
        queryCardInfo(info.activeCard || info.iccid, isDevice()).then(({ data: res }) => {
            if (res.code == 0) {      
                if (res.data.isRealName == 0 && res.data.realNameType == 1) {                    
                    state.needRealName = true;                 
                }
            state.cardInfo = res.data;
            if(res.data.endTime) Object.assign(state.cardInfo,{ expirationTime: daysUntil(res.data.endTime) })
            state.isRealName = res.data.isRealName; //获取实名状态
            
            //获取剩余流量
            state.surplus = res.data.residualFlow;
            state.usedFlow = res.data.usedFlow
            if (res.data.promptInfo && (res.data.promptInfo.promptMsg || res.data.promptInfo.promptImgUrl)) {
              state.promptDialogShow = true
              state.promptInfo = res.data.promptInfo
            }
            let flowPercent = 1,
              voicePercent = 1;
            //流量环形图绘制必备参数判断
            switch (res.data.totalFlow) {
              case 0:
              case null:
                let totalFlow = 1;
                flowPercent = 1;
                state.lineColor = ""; //未生效套餐时清空颜色值
                break;
              default:
                flowPercent = res.data.usedFlow / res.data.totalFlow;
                if (flowPercent >= 0.8 && flowPercent < 0.9) {
                  state.lineColor = "yellow";
                }
                if (flowPercent >= 0.9) {
                  state.lineColor = "red";
                }
                if (flowPercent >= 1) {
                  flowPercent = 1;
                }
                break;
            }
            state.flowPercent = flowPercent * 100
            //获取剩余语音
            state.voiceSurplus = res.data.residualVoice;
            //语音环形图绘制必备参数判断
            switch (res.data.totalVoice) {
              case null:
                state.showVoice = false;
                break;
              case 0:
                state.showVoice = true;
                voicePercent = 1;
                state.lineColor_ = ""; //未生效套餐时清空颜色值
                break;
              default:
                state.showVoice = true;
                voicePercent = res.data.usedVoice / res.data.totalVoice;
                if (voicePercent >= 0.8 && voicePercent < 0.9) {
                  state.lineColor_ = "yellow";
                }
                if (voicePercent >= 0.9) {
                  state.lineColor_ = "red";
                }
                if (voicePercent >= 1) {
                  voicePercent = 1;
                }
                break;
            }
            if (state.showVoice) {
              state.voicePercent = voicePercent
            }
            //存储用户的其它信息到sessionStorage中
            store.dispatch("SetUserInfo", {
              labels: res.data.labels || [],
              lastAgentId: res.data.lastAgentId,
              agentPath: res.data.agentPath,
              iccid: res.data.iccid,
              virtualId: res.data.virtualId,
              aliAppId: res.data.aliAppId,
              cardId: res.data.cardId,
              iccids: res.data.iccids,
              sim: res.data.sim,
              appid: res.data.AppID,
              wxPayAppid: res.data.wxPayAppid, // 通联appid
              apnId: res.data.apnId,
              money: res.data.money,
              userMoney: res.data.userMoney,
              maxWhitelist: res.data.maxWhitelist,
              operator: res.data.operator,
              passagewayType: res.data.passagewayType,
              nickname: res.data.nickname,
              mobile: res.data.mobile,
              totalVoice:
                res.data.totalVoice !== null ? res.data.totalVoice : "",
              apiCode: res.data.apiCode,
              device: isDevice(),
              promptInfo: res.data.promptInfo,
              paymentWxType: res.data.paymentWxType,
              paymentAliType: res.data.paymentAliType,
              cardType: res.data.cardType
            });
            state.openWorkOrder = res.openWorkOrder;
            // 卡诊断
            ctx.needCharge(state.cardInfo.iccid);
          }
        });
      },
      //获取卡号状态
      getCardStatus(info) {
        queryCardStatus(info.activeCard || info.iccid).then(({ data: res }) => {
          if (res.code == 55555) {
            Object.assign(state.cardInfo, {
              statusCode: res.code
            });
            return;
          }
          if (
            res.code == 55555 ||
            res.data.code == 88 ||
            res.data.code == 7 ||
            res.data.code == 12
          ) {
            state.cardUseAble = false;
          }
          if (res.code == 0) {
            Object.assign(state.cardInfo, {
              status: res.data.status,
              statusCode: res.data.code
            });
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //获取未读公告数
      getNoticeNum(params) {
        NoticeNum(params).then(({ data: res }) => {
          if (res.code == 0 && res.data.unreadCount != 0) {
            state.noticeNum = res.data.unreadCount;
          }
        });
      },
      //获取未读公告列表
      getNoticeList(params) {
        NoticeList(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.noticeList = res.data;
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //获取未读客服回复数量
      getServiceNum(params) {
        getUnreadTicketMessage(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.unreadMsgNumber = res.unreadMsgNumber;
            return;
          }
        });
      },
      //查询是否有通道链接
      queryRealNameUrl(card) {
        realNameUrlYuYin({ card },true).then(({ data: res }) => {
          // 若已经三要素实名，有通道链接 则保存通道链接
          if (
            res.code == 0 &&
            (res.data.realNameStatus == 2 || res.data.realNameStatus == 0) &&
            res.data.realNameUrl
          ) {
            state.realNameUrl = res.data.realNameUrl;
            return;
          }
        });
      },
      adDialogClose() {
        // 弹窗广告关闭重新获取卡状态数据
        methods.init();
      },
      // 换卡弹窗控制->当天仅弹出一次
			changeCardPopup(data){
				let date = new Date()
				let today =	'当前日期:' + date.getFullYear().toString() + '/' + (date.getMonth()+1).toString() + '/' + date.getDate().toString()
				let currentDay =  getStore({ name: `${state.cardInfo.iccid} - changeCardDialog` })
				if(!currentDay ||  currentDay != today){
					removeStore({ name: `${state.cardInfo.iccid} - changeCardDialog` });
					state.isTipsGetCardPopup = true
					ctx.$nextTick(()=>{
						ctx.$refs['adChangeCardDialog'].init(data)
					})
					setStore({
        		name: `${state.cardInfo.iccid} - changeCardDialog`,
        		content: today
      		});
				}
			},
      async getBannerList() {
        let { data } = await zyAdBannerList(1)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1;
            return 1;
          };
          state.bannerList = data.data.sort(stableSorting);
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      async getBannerDialog() {
        let { data } = await zyAdBannerList(2)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1;
            return 1;
          };
          if (data.data.length > 0) {
            state.dialogInfo = data.data.sort(stableSorting)[0];
            state.dialogShow = true;
          }
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      async getBannerInfo() {
        let { data } = await zyAdBannerInfo(1)
        if (data.code == 0) {
          state.bannerInfo = data.data
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      // 广告跳转
      openUrl(url, needParams) {
        // 若有跳转地址 且 需要携带参数 则拼接字段
        if (Boolean(url) && Number(needParams) == 1) {
          location.href = url + `&channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        }
        // 若有跳转地址 且 不需要携带参数 则直接跳转
        if (Boolean(url) && Number(needParams) == 0) {
          location.href = url
        }
      },
        // 检查卡
        checkCard (conf) {    
            return new Promise((resolve) => {                
                testCard({ card: conf.iccid, device: isDevice() }).then(({ data: res}) => {
                    if (res.msg == '该卡不存在') {
                        tool.alert(
                            {
                                title: '提示',
                                msg: res.msg
                            },
                            () => {
                                ctx.exit();
                            }
                        )
                        return resolve(false) 
                    }
                    resolve(true)
                }) 
            })   
        }, 
      async init() {
        //获取客服URL
        state.kefu_URL = window.SITE_CONFIG.kefu_URL;
        // 是否企微客服
        state.qiWei_kefu = window.SITE_CONFIG.qiWei_kefu || false;
        //获取用户信息
        const info = store.state.user.userInfo;
        const isContinueRequest = await ctx.checkCard(info);        
        if(!isContinueRequest) return;
        ctx.getAvailableCouponGoodsList(info.iccid)
        // 获取套餐信息
        ctx.setMeal(info)
        ctx.getCardInfo(info);
        //查询卡状态
        ctx.getCardStatus(info);
        //获取公告数量
        ctx.getNoticeNum(info.iccid);
        //获取未读公告列表
        ctx.getNoticeList({
          card: info.iccid,
          page: 1,
          limit: 30
        });
        //获取未读客服回复数量
        ctx.getServiceNum({
          card: info.iccid
        });
        //查询是否有通道链接
        ctx.queryRealNameUrl(info.iccid);       
        // console.log(info)
        //获取商城入口展示
        state.shopUrl = getStore({ name: "shopUrl" }) || ""; //需咨询
        // 查询广告
        ctx.getBannerList()
        ctx.getBannerDialog()
        ctx.getBannerInfo()
        // 优惠券
        getDialogCoupon({iccid: info.iccid}).then(({data:res})=>{
          if(res.code == 0){
            let list = res.data || [];
            if(list.length > 0){
              ctx.$nextTick(()=>{
                ctx.$refs.platfromCoupon.init(list);
              })
              let couponIds = list.map(v=> v.couponId)
              remindCoupon({iccid: info.iccid,couponIds}).then(({data:res})=>{});
            }
          }
        })

        console.log(getStore({ name: `tempSetMeal` }));
        if(getStore({ name: `tempSetMeal` })){
          removeStore({ name: "tempSetMeal", type: "session"});
          ctx.toComponent('personal-setMeal',{
            cardType: info.cardType,
            iccid: info.iccid,
            isInvoice: true
          })
        }
      },
      //获取叠加包提前停机天数
      getPreRechargeDay(info) {
        http
          .post("/card/getPreRechargeDay", { card: info.virtualId || info.iccid })
          .then(({ data: res }) => {
            if (res.code == 0) {
              state.preRechargeDay = res.preRechargeDay;
            } else {
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => { }
              );
            }
          });
      },
			
      setMeal(info) {
        // 查询套餐信息
        queryPackages({ card: info.iccid }).then(({ data: res }) => {
          if (res.code == 0) {
            state.alertMsg = res.list.alertMsg || ''
            state.basicDataList = res.list.basicPackage || "";
            state.speedDataList = res.list.speedPackage || "";
            state.buyMultiplePackages = Number(res.list.buyMultiplePackages) // 多套餐购买 0-关闭 1-开启
            ctx.$nextTick(()=>{
              state.active = Number(sessionStorage.getItem('ActiveIndex'))
            })
          return;
          }
          tool.alert(
            {
              title: "提示",
              msg: res.msg
            },
            () => { }
          );
        });
        ctx.getPreRechargeDay(info);
      },
      clickAdv() {
        advertLog({
          param: store.state.user.userInfo.iccid,
          type: 17,
          browserType: store.state.user.userInfo.device
        })
        methods.toComponent('personal-changecard')
      },
      // 穿戴类型
      clickChuangDai(res) {
        // 未充值套餐
        if (res.code == 2099) {
          tool.confirm(
            {
              title: "提示",
              msg: res.msg,
              cancelButtonText: "取消",
              confirmButtonText: "去充值",
              confirmButtonColor: tool.getThemeList(state.theme)
            },
            () => {
              state.active = 0
              sessionStorage.setItem('ActiveIndex', state.active)
            },
            () => { }
          );
        }
        // 未添加亲情号码
        if (res.code == 2100) {
          ctx.judgeIsWhitelist(res.code)
        }
      },    
      // 跳转小亿订单
      handleToOrder() {
        const concatParams = `channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        location.href = `${baseUrl}/pages/orderListPage/orderListPage?${concatParams}`
      },
      // 跳转小亿优选
      handleToShop() {
        location.href = `${baseUrl}?channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
      },
      // 获取优惠券数据
      getAvailableCouponGoodsList(iccid) {
          http
            .post("/card/getAvailableCouponGoodsList", { iccid })
            .then(({ data: res }) => {
              if (res.code == 0) {                  
                  state.couponGoodsList = res.data
              } else {
                tool.toast(
                  {
                    msg: res.msg,
                    duration: 1500
                  },
                  () => {}
                );
              }
            });
      },
      // 选中优惠券
      pasteHandle(item){
        console.log('选中优惠券');
        state.couponObject = item;
        nextTick(() => {
            ctx.$refs.couponNotice.isShow();
        });
      }   
    };
    //获取运营商logo 1、电信  2、移动 3、联通 4、聚合卡
    const operatorLogo = computed(() => {
      switch (state.cardInfo.operator) {
        case 1:
          return require('../../assets/image/yyPlatfrom/ChinaTelecom.png')
        case 2:
          return require('../../assets/image/yyPlatfrom/ChinaMobile.png')
        case 3:
          return require('../../assets/image/yyPlatfrom/ChinaUnicom.png')
        case 4:
          return require('../../assets/image/yyPlatfrom/operator4.png')
        default:
          return require('../../assets/image/yyPlatfrom/operator4.png')
      }
    });
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode;
      state.kefu_complaint_URL = window.SITE_CONFIG['kefu_complaint_URL']
      state.flowStatus = window.SITE_CONFIG['flowStatus'] || 'all'
      state.isSimple = store.state.user.isSimple
      methods.init();
    });
    onBeforeRouteLeave((to, from, next) => {
      if (!state.cardUseAble && to.name == "fb-list") {
        //换卡时间已过 可联系客服
        state.changeCardShow = false;
        next();
      } else if (state.cardInfo.statusCode == 16 && to.name !== "fb-list") {
        tool.toast(
          {
            msg: "超量停机状态不可操作，请联系客服",
            duration: 1500
          },
          () => { }
        );
      } else if (state.cardInfo.statusCode == 55555) {
        tool.toast(
          {
            msg: "注销状态不可操作",
            duration: 1500
          },
          () => { }
        );
      } else if (!state.cardUseAble && to.name !== "personal-changecard") {
        tool.toast(
          {
            msg: "故障卡不可操作",
            duration: 1500
          },
          () => { }
        );
      } else {
        next();
      }
    });
    onUnmounted(() => {
      console.log('移除支付组件');
      // 移除支付支付组件
      state.isSupportRecharge = false
      store.dispatch("SetUserInfo", { isPackage: true })
    })
    return { ...toRefs(state), ...methods, operatorLogo };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

// @include font_color("font_color1");
// @include background_color("background_color1");
.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .head {
    width: 100%;
    height: 404px;
    @include background_color("background_color1");

    .head_box {
      width: 686px;
      height: 292px;
      margin: auto;
      border-radius: 32px;
      box-shadow: 0px 8px 24px -8px rgba(0, 0, 0, 0.2), 0px 24px 24px -8px rgba(0, 0, 0, 0.2);
      position: relative;
      z-index: 1;
      margin-top: 40px;
      overflow: hidden;

      .head_box_padding {
        padding: 0px 32px;

        .head_box_info {
          display: flex;
          margin-top: 32px;

          .head_box_info_img {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            margin-right: 24px;
          }

          .head_box_info_name {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .head_box_info_name_text {
            font-size: 32px;
            line-height: 44px;
            color: #FFF6D7;
            display: flex;
            margin-right: 16px;
            align-items: center;

            div {
              max-width: 362px;
              overflow: hidden; //溢出隐藏
              text-overflow: ellipsis; //文本显示省略号
              white-space: nowrap; //不换行  /*默认normal 自动换行*/
              word-break: break-all;
            }

            img {
              width: 36px;
              height: 36px;
              margin-left: 16px;
            }
          }

          .head_box_info_name_num {
            font-size: 28px;
            line-height: 40px;
            color: #FFF6D7;
            opacity: 0.8;
          }
        }

        .head_box_crad {
          font-size: 24px;
          line-height: 32px;
          color: #FFC24B;
          margin-top: 49px;

          .head_box_crad_status {
            display: flex;
            justify-content: space-between;

            div {              
              display: flex;
              align-items: center;

              img {
                width: 36px;
                height: 36px;
                margin-right: 8px;
              }
            }
          }

          .head_box_crad_iccid {
            margin-top: 16px;
            font-size: 24px;
            line-height: 32px;
            color: #FFF6D7;
            opacity: 0.8;
            letter-spacing: 3px;
            // filter: blur(0.5px);
          }
        }

        .head_box_noRealName {
          position: absolute;
          top: 36px;
          right: 30px;
          .btn{
            border: none;
            background-color: #FFC24B;
            color: rgb(254, 58, 57);
          }
        }
        .head_box_isRealName {
          width: 120px;
          height: 120px;
          position: absolute;
          top: 0px;
          right: 0px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .head_box_bj {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
        }
      }
    }

  }

  // teb栏
  .teb {
    width: 100%;
    height: calc(100vh - 372px);
    margin-top: -32px;
    background-color: #FFF6D8;
    border-radius: 32px 32px 0px 0px;
    overflow: hidden;
    position: relative;

    .teb_box {
      width: 100%;
      // height: calc(100% - 88px);
      height: calc(100vh - 460px);
      overflow: hidden;
      overflow-y: auto;
      position: relative;
    }
    .empty {
      color: rgba(0, 0, 0, 0.4);
      font-size: 32px;
      text-align: center;
      padding: 24px;    
    }
  }

  .teb_recharge {
    // height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    padding: 0px 24px;
    margin-top: 32px;
    margin-bottom: 32px;

    .teb_recharge_box_active {
      background: #FFE5A6 !important;
      border: 4px solid #FFC24B !important;
      box-shadow: 4px 4px 4px -1px rgba(252, 227, 167, 0.4), 4px 4px 24px -1px rgba(237, 215, 142, 0.5) !important;
    }

    .teb_recharge_box {
      width: 338px;
      height: 232px;
      border: 1px solid #FFC24B;
      border-radius: 24px;
      position: relative;
      margin-bottom: 46px;

      .teb_recharge_padding {
        padding: 48px 16px 0px 16px;

        .teb_recharge_box_title {
          width: 272px;
          height: 88px;
          font-size: 3vw;
          font-weight: bold;
          line-height: 44px;
          color: #453310;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //控制显示几行
          -webkit-box-orient: vertical; //webbox方向
        }

        .teb_recharge_box_price {
          display: flex;
          align-items: baseline;
          font-size: 24px;
          line-height: 56px;
          margin-top: 16px;

          .teb_recharge_box_price_actual {
            @include font_color("font_color1");
            margin-right: 8px;

            span {
              font-size: 32px;
              font-weight: bold;
            }
          }

          .teb_recharge_box_price_original {
            font-size: 24px;
            color: rgba(188, 168, 122, 0.6);
            text-decoration: line-through;
          }
          .coupon_price {
            height: 42px;
            line-height: 42px;
            padding: 0px 8px;
            border-radius: 108px;
            color: #FE3A39;
            font-size: 24px;
            background: rgba(254,58,57,0.15);
          }
        }

        .teb_recharge_box_select {
          width: 216px;
          height: 48px;
          padding: 0px 20px;
          font-size: 24px;
          color: #FFE4A6;
          line-height: 48px;
          background: #FE1E14;
          border-radius: 32px 32px 32px 0px;
          position: absolute;
          top: -16px;
          left: -3px;
          display: flex;
          align-items: center;
          z-index: 2;

          div {
            width: 172px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //控制显示几行
            -webkit-box-orient: vertical; //webbox方向
          }

          img {
            width: 36px;
            height: 36px;
            margin-right: 6px;
            vertical-align: sub;
          }
        }

        .teb_recharge_box_recommend {
          // width: 92px;
          height: 48px;
          padding: 0px 20px;
          font-size: 24px;
          color: #FFE4A6;
          line-height: 48px;
          background: linear-gradient(92.13deg, #35374F 3.62%, #494E6A 46.22%);
          border-radius: 32px 32px 32px 0px;
          position: absolute;
          top: -16px;
          left: -3px;
          display: flex;
          align-items: center;
          z-index: 1;

          img {
            width: 36px;
            height: 36px;
            margin-right: 6px;
          }
        }

        .recommend_active {
          // width: 92px;
          height: 48px;
          padding: 0px 12px;
          font-size: 24px;
          color: #FFE4A6;
          line-height: 48px;
          background: linear-gradient(92.13deg, #35374F 3.62%, #494E6A 46.22%);
          border-radius: 32px 32px 32px 0px;
          position: absolute;
          top: -16px;
          right: 0px;
          display: flex;
          align-items: center;
          z-index: 1;
          img {
            width: 36px;
            height: 36px;
            margin-right: 6px;
          }
        }
      }
    }

    .teb_recharge_box_package {
      width: 338px;
      height: 256px;
      border: 2px solid #FFC24B;
      border-radius: 24px;
      position: relative;
      margin-bottom: 54px;

      .teb_recharge_package_padding {
        padding: 24px 24px 0px 24px;

        .teb_recharge_box_package_title {
          // width: 162px;
          height: 88px;
          font-size: 3vw;
          font-weight: bold;
          line-height: 44px;
          color: #453310;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //控制显示几行
          -webkit-box-orient: vertical; //webbox方向
        }

        .teb_recharge_box_package_price {
          display: flex;
          align-items: baseline;
          font-size: 24px;
          line-height: 56px;
          margin-top: 16px;
          justify-content: center;

          .teb_recharge_box_package_price_actual {
            @include font_color("font_color1");

            span {
              font-size: 40px;
            }
          }
        }

        .teb_recharge_box_package_month {
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: rgba(188, 168, 122, 0.6);
          margin-top: 16px;
        }

      }
    }
  }

  .teb_serve_padding {
    padding: 32px;

    .teb_serve {
      border-radius: 24px;
      background: #FFFFFF;
      height: 316px;
      overflow: hidden;

      .teb_serve_circle {
        display: flex;
        justify-content: space-around;
        padding: 30px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // padding-bottom: 40px;
      }

      .teb_serve_data {
        line-height: 96px;
        display: flex;
        justify-content: space-around;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.6);

      }
    }

    .teb_list {
      background: #FFFFFF;
      border-radius: 24px;
      margin-top: 24px;

      ul {
        padding: 16px;
        display: flex;
        flex-wrap: wrap;

        li:active {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 16px;
        }

        li {
          width: 163px;
          height: 163px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 48px;
            height: 48px;
          }

          p {
            margin-top: 16px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.8);

          }

          .tips_num {

            // width: 38px;
            height: 38px;
            line-height: 38px;
            padding: 0px 8px;
            border-radius: 28px;
            font-size: 24px;
            color: #ffffff;
            background: #f34721;
            position: absolute;
            top: 18px;
            left: 81px;
            text-align: center;
          }
        }
      }
    }

    .teb_logOut {
      font-size: 32px;
      line-height: 100px;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
      background: #FFFFFF;
      border-radius: 16px;
      margin-top: 24px;

    }
  }


}
/deep/.van-tab__text{
  font-size: 32px;
}

 .banner {
    padding: 30px;
    .my-swipe .van-swipe-item {
        width: 686px;
        min-height: 138px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        opacity: 1;
        border-radius: 16px;
        background: url("~_ASSETS_/image/yyPlatfrom/coupon_back.png") center/cover no-repeat;
        .swipe-content{
            display: flex; 
            margin: 0 26px;
            justify-content: space-between;
            align-items: center;  
            .goodsName{
                max-width: 425px;                               
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .unit,.zero,.buy{
                font-size: 12px;
                font-weight: bold;
                font-family: "PingFang SC";
            }
            .salePrice{              
                font-weight: bold;
                font-family: "PingFang SC";
                font-size: 4.4vw;
            }
            .van-button--primary {                  
                width: 176px;
                height: 60px;
                // border-radius: 16px;
                border-radius: 57px;
                border: none;
                opacity: 1;
                color: rgba(254, 58, 57, 1);
                background: linear-gradient(180deg, rgb(255, 253, 244) 0%, rgb(254, 235, 143) 100%);
            }
        }
    }
    /deep/ .van-swipe__indicator--active{
        background-color: rgb(254, 58, 57);
    }
    /deep/ .van-swipe__indicator{
        background-color: rgba(254, 58, 57, 1);
    }
}
.realNameBox {
  display: flex;
  flex-direction: column;
  width: 615px;
  margin: 0 auto;
  height: 300px;
  .appbar {
    // @include border-1px(#eeeeee, bottom);
    border-bottom: 1px solid #eeeeee;
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }  
  }
  ::after {
    background: #2196f3 !important;
  }
  .realNameContent {
    flex: 1;
    overflow: auto;
    .title {
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
      height: 200px;
      line-height: 200px;
    }
    .choice {
      height: 98px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 98px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }      
      .confirm {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #0bb20c;
      }
    }
  }
}
//实名弹窗样式
.auth_popup {
    width: 590px;
    height: 869px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .goauthenticationImg {
      padding-top: 20px;
      text-align: center;
      img {
        width: 172px;
        height: 170px;
      }
      p {
        font-size: 34px;
        color: #333;
        margin: 10px auto;
      }
    }
    .goauthenticationTitle {
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
      text-align: center;
      padding: 0 26px;
    }
    .tips_desc {
      width: 530px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 20px;
      border: 2px dashed #aaaaaa;
      .tips_content {
        font: {
          size: 22px;
          weight: 400;
        }
        word-break: break-all;
        color: #aaaaaa;
        padding: 24px;
        text-align: left;
      }
    }
    .viewDetail {
      text-align: center;
      margin-bottom: 20px;
      .auth-button,
      .detail-button {
        width: 90%;
        border-radius: 16px;
        border: none;
        @include background_color("background_color1");
      }
    }
  }
</style>
